<script setup lang="ts">
import { computed } from "vue";
import AuthInfo from "@/components/layout/auth/AuthInfo.vue";
import ButtonMain from "@/components/ui/ButtonMain.vue";
import AuthLayout from "@/components/layout/auth/AuthLayout.vue";
import { isIOSMobileBrowser } from "@/util/functions";
import { changeLanguageToBrowserLocale } from "@/util/translations";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const isIOSBrowser = computed<boolean>(() => {
  return isIOSMobileBrowser();
});

const androidIntentHref = computed<string>(() => {
  const url = window.location.href;
  const withoutHttp = url.substring(url.indexOf("://"));
  return `intent${withoutHttp}#Intent;scheme=https;package=com.bioniq.health;end`;
});

const handleNextIOS = () => {
  const url = new URL(window.location.href);
  const newUrl = url.href.replace(/(https|http)/i, "bioniq");
  return window.location.replace(newUrl);
};

changeLanguageToBrowserLocale();
</script>

<template>
  <AuthLayout>
    <AuthInfo>
      <template v-slot:text>
        <div :data-text="'magic'" class="info-text">
          <span>{{ t("MAGIC_LINK.REDIRECTED_INFO") }}</span>
        </div>
      </template>
      <template v-slot:button>
        <ButtonMain v-if="isIOSBrowser" @click="handleNextIOS">
          <template v-slot:btnText>{{ t("MAGIC_LINK.CONTINUE_APP") }}</template>
        </ButtonMain>
        <ButtonMain v-else>
          <template v-slot:btnText>
            <a :href="androidIntentHref">{{ t("MAGIC_LINK.CONTINUE_APP") }}</a>
          </template>
        </ButtonMain>
      </template>
    </AuthInfo>
  </AuthLayout>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/auth.css";

@mixin info-text;

:deep(.button-container) {
  padding: 0;
}
:deep(.btn-text) {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  color: unset;
  font-size: unset;
  text-decoration: none;
}
a:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
</style>
