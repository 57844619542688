<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ButtonMain from "@/components/ui/ButtonMain.vue";

const emit = defineEmits<{
  (e: "startQuestionnaire"): void;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="questionnaire-description-screen-container">
    <div class="title">{{ t("QUESTIONNAIRES.DESCR.TITLE") }}</div>
    <div class="text">{{ t("QUESTIONNAIRES.DESCR.TEXT") }}</div>
    <ButtonMain @click="emit('startQuestionnaire')" class="get-started-btn">
      <template v-slot:btnText>{{ t("QUESTIONNAIRES.DESCR.GET_STARTED") }}</template>
      <template v-slot:icon>
        <img src="@/assets/icons/checkmark.svg" alt="checkmark" />
      </template>
    </ButtonMain>
  </div>
</template>

<style scoped>
.questionnaire-description-screen-container {
  & .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1em;
  }
  & .text {
    font-size: 1.2rem;
    margin-bottom: 15em;
  }
  & .get-started-btn {
    width: 8em;
  }
}

@media (--max-phone-width) {
  .questionnaire-description-screen-container {
    & .title {
      font-size: 2rem;
    }
    & .text {
      font-size: 1.1rem;
      margin-bottom: 10em;
    }
    & .get-started-btn {
      width: 8em;
    }
  }
}
</style>
