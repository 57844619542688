<script setup lang="ts">
import { reactive, ref, computed } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { validatePassword } from "@/util/validations/authValidations";
import ButtonMain from "@/components/ui/ButtonMain.vue";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import { isMobileBrowser } from "@/util/functions";
import MagicLinkMobile from "@/components/layout/MagicLinkMobile.vue";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import AuthLayout from "@/components/layout/auth/AuthLayout.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const authStore = useAuthStore();
const route = useRoute();

// ----- Passwords Business Logic -----
const password = ref("");
const passwordRepeat = ref("");

const handlePasswordUpdate = (value: string) => {
  if (passwordWrong.newPass) passwordWrong.newPass = false;
  password.value = value;
};

const handlePasswordRepeatUpdate = (value: string) => {
  if (passwordWrong.repeatPass) passwordWrong.repeatPass = false;
  passwordRepeat.value = value;
};

const handleNext = () => {
  memberTriedToLoginAtLeastOnce.value = true;
  if (!allValid.value) return;

  const token = route.query.token as string;
  if (token) return handlePasswordRequest(token);

  handleNewUser();
};

const handlePasswordRequest = async (token: string) => {
  const payload = { token, password: password.value };
  const [err, success]: boolean[] = await authStore.postConfirmPasswordRequest(payload);
  if (err) {
    passwordWrong.newPass = true;
    passwordWrong.repeatPass = true;
  }
  if (success) authStore.redirectTo(RouteNameMenu.MENU_ANALYTICS);
};

const handleNewUser = async () => {
  const [err, data]: any[] = await authStore.postUserSignUp(password.value);
  if (err) return authStore.redirectTo(RouteName.AUTH_ERROR_CONFIRM);

  if (!data.confirmed) return authStore.redirectTo(RouteName.AUTH_EMAIL_CONFIRM);
};
// ----- Passwords Business Logic -----

// ----- Validations -----
const passwordWrong = reactive({
  newPass: false,
  repeatPass: false,
});
const memberTriedToLoginAtLeastOnce = ref(false);

const isPasswordValid = computed<boolean>(() => {
  return validatePassword(password.value) && !passwordWrong.newPass;
});

const isPasswordRepeatValid = computed<boolean>(() => {
  return validatePassword(passwordRepeat.value) && !passwordWrong.repeatPass;
});

const arePasswordsMatching = computed<boolean>(() => {
  return password.value === passwordRepeat.value;
});

const allValid = computed<boolean>(() => {
  return isPasswordValid.value && isPasswordRepeatValid.value && arePasswordsMatching.value;
});

const isInputErrored = (val: boolean) => {
  return (
    (!val && memberTriedToLoginAtLeastOnce.value) ||
    (!arePasswordsMatching.value && memberTriedToLoginAtLeastOnce.value)
  );
};
// ----- Validations -----

const isMobile = computed<boolean>(() => {
  return isMobileBrowser();
});
</script>

<template>
  <!-- if we are on the Forgot Password redirect it means there is no email; -->
  <!-- if there is an email it means we are on the new user flow -->
  <AuthLayout v-if="!isMobile || authStore.email">
    <div class="auth-password">
      <div class="text-info">{{ t("LOGIN.NEW_PASS.TITLE") }}</div>
      <form @submit.prevent="handleNext" novalidate>
        <div class="password-input">
          <TextInputBox
            :inputName="'password'"
            :labelText="t('LOGIN.NEW_PASS.NEW')"
            :value="password"
            @update="handlePasswordUpdate"
            :errored="isInputErrored(isPasswordValid)"
            :disabled="false"
          />
        </div>
        <div class="password-input">
          <TextInputBox
            :inputName="'password'"
            :labelText="t('LOGIN.NEW_PASS.RETYPE')"
            :value="passwordRepeat"
            @update="handlePasswordRepeatUpdate"
            :errored="isInputErrored(isPasswordRepeatValid)"
            :disabled="false"
          />
        </div>
      </form>
      <div class="next-button">
        <ButtonMain @click="handleNext">
          <template v-slot:btnText>{{ t("LOGIN.CONFIRM") }}</template>
        </ButtonMain>
      </div>
    </div>
  </AuthLayout>
  <MagicLinkMobile v-else></MagicLinkMobile>
</template>

<style scoped>
.text-info {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 2rem;
}
.password-input {
  margin-bottom: 1rem;
}
.next-button {
  height: 3em;
  margin-top: 2em;
}
</style>
