<script setup lang="ts">
import { ref, onUnmounted, type Ref } from "vue";
import CustomSuspense from "@/components/helpers/CustomSuspense.vue";
import { useCheckupResultsStore } from "@/stores/checkup-results";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import TogglerHorizontal from "@/components/ui/TogglerHorizontal.vue";
import GraphLine from "@/components/blood-drop/GraphLine.vue";
import errorImg from "@/assets/icons/error-1.webp";
import { usePopupBox } from "@/composables/ui/usePopupBox";
import PopupBox from "@/components/ui/PopupBox.vue";
import { useLayoutStore } from "@/stores/layout";
import { useStatusReference } from "@/composables/useStatusReference";
import ModalMain from "@/components/ui/ModalMain.vue";

const { navbarOpened } = storeToRefs(useLayoutStore());

const { t } = useI18n();
const checkupStore = useCheckupResultsStore();
const { marker } = storeToRefs(checkupStore);

const teleportModalTo = "body";
const teleportPopupTo = ".checkup-modal";
const errorFetchMarker = { text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.ERROR_API"), icon: errorImg };

enum Section {
  CURRENT = "current",
  COMPARISON = "comparison",
}
const sectionSelected = ref<Section>(Section.CURRENT);

const props = defineProps<{
  bloodTestId: number;
  markerId: number;
}>();
const emit = defineEmits<{
  (e: "clickOutside"): void;
}>();

const { statusType, percentValue, markerReferenceValues } = useStatusReference(marker);

const emitClickOutside = () => {
  emit("clickOutside");
};

const updateSelectedSection = (val: string) => {
  sectionSelected.value = val as Section;
};

const infoIcon = ref<HTMLElement | null>(null);

const { showPopupBox, togglePopupBox, closePopupBox, clickPosition, classDown } = usePopupBox(
  teleportPopupTo,
  infoIcon as Ref<HTMLElement>,
  true
);

// ----- Hidden Body Overflow -----
const setOverflowPropOnBody = (val: "hidden" | "overlay") => {
  document.querySelector("body")?.style.setProperty("overflow", val);
};
setOverflowPropOnBody("hidden");
onUnmounted(() => setOverflowPropOnBody("overlay"));
// ----- Hidden Body Overflow -----
</script>

<template>
  <Teleport :to="teleportModalTo">
    <ModalMain :modalType="'main'" @clickOutside="emitClickOutside" :hasGradient="true">
      <div class="checkup-modal">
        <CustomSuspense
          :apiCall="() => checkupStore.fetchMarker(props.markerId, props.bloodTestId)"
          :errorObj="errorFetchMarker"
          :shouldMakeApiCall="true"
          :shouldShowContent="false"
        >
          <template v-slot:content>
            <div class="modal-content">
              <div class="title-row">
                <div class="title">{{ marker!.bloodMarker.name }}</div>
                <div class="close-icon" @click="emitClickOutside">
                  <img src="@/assets/icons/close.svg" alt="close" />
                </div>
              </div>
              <div class="parameter-container">
                <div class="row toggler-row">
                  <div class="parameter-title">
                    {{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.PARAMETER_RESULTS") }}
                  </div>
                  <TogglerHorizontal
                    :values="[
                      { value: Section.CURRENT, transl: t('SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.CURRENT') },
                      {
                        value: Section.COMPARISON,
                        transl: t('SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.COMPARISON'),
                      },
                    ]"
                    :selectedValue="sectionSelected"
                    @toggle-change="updateSelectedSection"
                  />
                </div>
                <div v-if="sectionSelected === Section.CURRENT" class="parameter-one">
                  <template v-if="!!statusType">
                    <div class="row">
                      <div class="range">
                        <div class="normal">{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.NORMAL") }}</div>
                        <div class="range">{{ markerReferenceValues }}</div>
                      </div>
                      <div class="info-icon" ref="infoIcon" @click="togglePopupBox">
                        <img src="@/assets/icons/info.svg" alt="info" />
                      </div>
                      <Teleport :to="teleportPopupTo">
                        <PopupBox
                          v-if="showPopupBox"
                          v-click-outside:[infoIcon]="closePopupBox"
                          :classDown="classDown"
                          :position="clickPosition"
                        >
                          <div class="popup-content">Popup content here</div>
                        </PopupBox>
                      </Teleport>
                    </div>
                    <div class="graph-container">
                      <div class="value">{{ `${marker!.value} ${marker!.unit}` }}</div>
                      <GraphLine :valuePercent="percentValue" />
                      <div class="low-high">
                        <div>{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.LOW") }}</div>
                        <div>{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.HIGH") }}</div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="marker-missing-info">
                      <div class="question-icon">
                        <img src="@/assets/icons/question-mark.webp" alt="question" />
                      </div>
                      <div class="text">{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.UNKNOWN") }}</div>
                    </div>
                  </template>
                </div>
                <div v-if="sectionSelected === Section.CURRENT" class="parameter-two">
                  <div class="title">{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.BODY_FNC") }}</div>
                  <div class="text">{{ marker!.bloodMarker.description }}</div>
                </div>
              </div>
            </div>
          </template>
        </CustomSuspense>
      </div>
    </ModalMain>
  </Teleport>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/ui.css";
@import "@/assets/styles/navbars.css";
@import "@/assets/styles/layout.css";
@import "@/assets/styles/blood-drop.css";

.checkup-modal {
  height: 100%;
}
.parameter-container :deep(.toggler-horizontal-container .item) {
  font-size: 0.9em !important;
}

.modal-content .title-row {
  padding-bottom: 2em;
  border-bottom: 1px solid var(--clr-grey-3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-content .title {
  font-size: 1.8em;
  font-weight: bold;
}
.modal-content .close-icon {
  width: 1em;
  height: 1em;
  cursor: pointer;
}
.modal-content .close-icon img {
  width: 100%;
  height: 100%;
}
.modal-content .parameter-container {
  margin-top: 2em;
}
.modal-content .parameter-container .parameter-title {
  font-weight: bold;
  font-size: 1.1em;
}
.modal-content .parameter-container .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
}
.modal-content .parameter-container .range {
  font-size: 0.9em;
  color: var(--clr-blue-1);
  letter-spacing: 0.03em;
}
.modal-content .parameter-container .info-icon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}
.modal-content .parameter-container .info-icon img {
  width: 100%;
  height: 100%;
}
.modal-content .parameter-one {
  border-bottom: 1px solid var(--clr-grey-3);
  padding-bottom: 2em;
  min-height: 10em;
}
.modal-content .parameter-two {
  margin-top: 1.5em;
}
.modal-content .parameter-two .title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 1.5em;
}
.modal-content .parameter-two .text {
  font-size: 0.9em;
  letter-spacing: 0.03em;
}

.modal-content .graph-container .value {
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 1em;
}
.modal-content .graph-container .low-high {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

@mixin marker-missing-info;

@media (--max-phone-width) {
  .modal-content .title-row {
    padding-bottom: 1em;
  }
  .modal-content .title-row .title {
    font-size: 1.3em;
  }
  .modal-content .title-row .close-icon {
    width: 0.8em;
    height: 0.8em;
  }
  .parameter-container :deep(.toggler-horizontal-container .item) {
    font-size: 0.8em !important;
  }
  .modal-content .parameter-one {
    padding-bottom: 1em;
  }
  .modal-content .parameter-container {
    margin-top: 1em;
  }
  .modal-content .parameter-container .parameter-title {
    font-size: 1em;
  }
  .modal-content .parameter-container .toggler-row {
    flex-direction: column;
    row-gap: 0.7em;
    margin-bottom: 1em;
  }
  .modal-content .parameter-container .toggler-row .parameter-title {
    order: 1;
  }
  .modal-content .parameter-container .range {
    font-size: 0.8em;
    letter-spacing: 0em;
  }
  .modal-content .parameter-container .info-icon {
    width: 1.2em;
    height: 1.2em;
  }
  .checkup-modal :deep(.popup-container .popup-content) {
    font-size: 0.9em;
  }
  .modal-content :deep(.graph-line-container) {
    margin-bottom: -0.5em;
  }
  .modal-content .graph-container .value {
    font-size: 1.1em;
    margin-bottom: 0.7em;
  }
  .modal-content .graph-container .low-high {
    font-size: 0.7em;
  }

  .modal-content .parameter-two {
    margin-top: 1em;
  }
  .modal-content .parameter-two .title {
    font-size: 1em;
    margin-bottom: 1em;
  }
  .modal-content .parameter-two .text {
    font-size: 0.8em;
  }
}
</style>
