<script setup lang="ts">
import { reactive, computed, ref, watch, toRefs, toRef } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import {
  validateString,
  validateAlphaNumeric,
  validateBirthDate,
  validateHeight,
  validateHeightImperial,
  validateWeight,
} from "@/util/validations/memberProfileValidations";
import { useMemberStore, HeightUnit, WeightUnit, Gender, HeightImperialUnit } from "@/stores/member";
import DateInput from "@/components/ui/DateInput.vue";
import DropdownFilteredSelector from "@/components/ui/DropdownFilteredSelector.vue";
import SettingsDetailLayout from "@/components/layout/settings/DetailLayout.vue";
import SettingsInput from "@/components/ui/SettingsInput.vue";
import type {
  MemberProfile_Settings_Information,
  MemberProfile_Settings_PUT,
  MemberProfile_Settings_Validations,
} from "@/types/settings-page/Profile";
import { LOCALE } from "@/util/constants";
import { useAvatar } from "@/composables/useAvatar";
import { usePhoneNumber, parsePhoneNo } from "@/composables/usePhoneNumber";
import { useToastMsg } from "@/composables/useToastMsg";
import { watchEffect } from "vue";
import { useHeightFormats } from "@/composables/useHeightFormats";
import { transformKgToPounds, transformPoundsToKg } from "@/util/functions";

const userInformationDefault: MemberProfile_Settings_Information = {
  account: {
    firstName: "",
    lastName: "",
    gender: Gender.CRAZY,
  },
  phoneNumber: {
    e164: "",
    value: "",
    countryCode: "",
    isValid: true,
  },
  birthday: "",
  height_Cm: "",
  height_Imp: {
    feet: "",
    inches: "",
  },
  weight: "",
  heightUnit: HeightUnit.CM,
  weightUnit: null,
  contentLocale: LOCALE.EN,
  avatar: null,
};

const { t } = useI18n();
const memberStore = useMemberStore();

const { profile, getHeightInCm, getHeightInFeetInches } = storeToRefs(memberStore);
const userInformation = reactive<MemberProfile_Settings_Information>(structuredClone(userInformationDefault));

const { showMessage: showToastMessage } = useToastMsg();
const userTriedTSaveOnAtLeastOnce = ref(false);

// ----- Phone Number -----
const { phoneNumber } = toRefs(userInformation);
const { updatePhoneNumber, updatePhoneNumberCountryCode, isPhoneNumberErrored } = usePhoneNumber(
  phoneNumber,
  userTriedTSaveOnAtLeastOnce
);
// ----- Phone Number -----

// ----- Submit Button -----
const submitMessage = ref("");
const disabledSubmitButton = ref(true);
const changeDisabledSubmitBtnToFalse = () => (disabledSubmitButton.value = false);
const profileInformation = computed<MemberProfile_Settings_Information | null>(() => {
  // * This is like a Store Getter, but I prefer to have it here
  // * It is used for comparing old date with new data

  if (!profile.value) return null;
  return {
    account: {
      firstName: profile.value.account.firstName,
      lastName: profile.value.account.lastName,
      gender: profile.value.account.gender,
    },
    phoneNumber: { ...parsePhoneNo(profile.value.phoneNumber) },
    birthday: profile.value.birthday,
    height_Cm: getHeightInCm.value,
    height_Imp: getHeightInFeetInches.value,
    weight: profile.value.weight?.toString() || "",
    heightUnit: profile.value.heightUnit,
    weightUnit: profile.value.weightUnit,
    contentLocale: profile.value.contentLocale,
    avatar: profile.value.avatar ? { ...profile.value.avatar } : null,
  };
});

watch(
  () => JSON.parse(JSON.stringify(userInformation)),
  (newValue) => updateDisabledButton(newValue)
);

const updateDisabledButton = (val: MemberProfile_Settings_Information) => {
  const noErrors = (() => {
    return Object.values(custInfoValidationErrors.value)
      .map((val: boolean | { [key in HeightImperialUnit]: boolean }) => {
        if (typeof val === "boolean") return !val;
        return !val[HeightImperialUnit.FEET] && !val[HeightImperialUnit.INCHES];
      })
      .every((val) => !!val);
  })();
  if (JSON.stringify(val) !== JSON.stringify(profileInformation.value) && noErrors) {
    disabledSubmitButton.value = false;
  } else {
    disabledSubmitButton.value = true;
  }
};
// ----- Submit Button -----

// ----- Avatar -----
const { avatarImgSrc, avatarForm, avatarFile, getNewAvatarPayloadOrNull } = useAvatar(
  () => userInformation.avatar?.thumbUrl || null,
  changeDisabledSubmitBtnToFalse
);
// ----- Avatar -----

// ----- Validations -----
const custInfoValidationErrors = computed<MemberProfile_Settings_Validations>(() => {
  return {
    firstName: !validateAlphaNumeric(userInformation.account.firstName.replace(/ /g, "")),
    lastName: !validateAlphaNumeric(userInformation.account.lastName.replace(/ /g, "")),
    phoneNumber: !userInformation.phoneNumber.isValid,
    gender: false,
    birthday: !validateBirthDate(userInformation.birthday),
    height_Cm: userInformation.heightUnit === HeightUnit.CM ? !validateHeight(userInformation.height_Cm) : false,
    height_Imp:
      userInformation.heightUnit === HeightUnit.INCHES
        ? validateHeightImperial(userInformation.height_Imp)
        : { feet: false, inches: false },
    weight: !validateWeight(parseFloat(userInformation.weight), userInformation.weightUnit as WeightUnit),
    heightUnit: false,
    weightUnit: false,
    contentLocale: !validateString(userInformation.contentLocale),
    avatar: false,
  };
});
// ----- Validations -----

const updateMemberPrimitiveInfo = <
  T extends MemberProfile_Settings_Information[K],
  K extends keyof Omit<MemberProfile_Settings_Information, "phoneNumber" | "account" | "avatar">
>(
  val: T,
  key: K
): void => {
  if (!val) return;
  userInformation[key] = val;
};

const updateMemberAccountData = <
  T extends MemberProfile_Settings_Information["account"][K],
  K extends keyof MemberProfile_Settings_Information["account"]
>(
  value: T,
  key: K
): void => {
  userInformation.account[key] = value;
};

const defaultCountry = computed<string>(() => {
  return userInformation?.phoneNumber?.countryCode || profile.value?.countryCode || "";
});

const serializeUserInfoForStore = async () => {
  const newAvatar = await getNewAvatarPayloadOrNull();
  const height = getHeightInCmOrInches();

  const payload: MemberProfile_Settings_PUT = {
    account: {
      firstName: userInformation.account.firstName,
      gender: userInformation.account.gender,
      lastName: userInformation.account.lastName,
    },
    phoneNumber: userInformation.phoneNumber.e164,
    birthday: userInformation.birthday,
    height,
    weight: userInformation.weight,
    heightUnit: userInformation.heightUnit,
    weightUnit: userInformation.weightUnit,
    contentLocale: userInformation.contentLocale,
  };
  if (newAvatar) payload.avatar = newAvatar;
  return payload;
};

const onSubmit = async () => {
  userTriedTSaveOnAtLeastOnce.value = true;
  if (disabledSubmitButton.value) return;

  const payload = await serializeUserInfoForStore();
  const [error, _val] = await memberStore.updateMemberProfile(payload);

  if (error) showToastMessage({ msg: t("TOAST.ERROR.TRY_AGAIN"), type: "error" });
  else showToastMessage({ msg: t("TOAST.SUCCESS.PROFILE_UPDATED"), type: "success" });
};

// ----- Height -----
const { activeHeightComponent, activeHeightComponentData, getHeightInCmOrInches } = useHeightFormats(
  {
    height_Cm: toRef(userInformation, "height_Cm"),
    height_Imp: toRef(userInformation, "height_Imp"),
    heightUnit: toRef(userInformation, "heightUnit"),
  },
  () => profileInformation.value!.heightUnit,
  () => {
    return {
      height_Cm: custInfoValidationErrors.value.height_Cm,
      height_Imp: custInfoValidationErrors.value.height_Imp,
    };
  },
  (val: string) => updateMemberPrimitiveInfo(val, "height_Cm")
);
// ----- Height -----

// ----- Weight -----
watch(
  () => userInformation.weightUnit,
  (newUnit: WeightUnit | null, oldUnit: WeightUnit | null) => {
    if (!oldUnit) return;

    if (newUnit === WeightUnit.KG) {
      userInformation.weight = transformPoundsToKg(userInformation.weight);
    } else if (newUnit === WeightUnit.POUNDS) {
      userInformation.weight = transformKgToPounds(userInformation.weight);
    }
  }
);
// ----- Weight -----

// ----- State Update -----
const updateUserInformationFromStore = () => {
  // * Keeps local state up to date with Pinia state on initialization and after Save
  userInformation.account.firstName = profile.value!.account.firstName || "";
  userInformation.account.lastName = profile.value!.account.lastName || "";
  userInformation.account.gender = profile.value!.account.gender;
  userInformation.phoneNumber = parsePhoneNo(profile.value!.phoneNumber);
  userInformation.birthday = profile.value!.birthday;
  userInformation.height_Cm = getHeightInCm.value;
  userInformation.height_Imp = structuredClone(getHeightInFeetInches.value); // * Very important to copy return value of getter here, otherwise object in memory will be the same as the one from profileInformation computed
  userInformation.weight = profile.value!.weight.toString();
  userInformation.heightUnit = profile.value!.heightUnit;
  userInformation.weightUnit = profile.value!.weightUnit;
  userInformation.contentLocale = profile.value!.contentLocale || LOCALE.EN;
  userInformation.avatar = profile.value!.avatar;
};

watchEffect(() => updateUserInformationFromStore());
// ----- State Update -----
</script>

<template>
  <div class="profile-information-container">
    <SettingsDetailLayout>
      <template v-slot:title>
        <span>{{ t("SETTINGS.PROFILE.PROFILE_INFO") }}</span>
      </template>
      <div class="information-container">
        <div class="user-information">
          <form class="avatar-container" enctype="multipart/form-data" ref="avatarForm">
            <input
              id="avatar-file"
              name="avatar-file"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref="avatarFile"
              hidden
            />
            <div class="avatar-img">
              <img :src="avatarImgSrc" alt="user-avatar" />
            </div>
            <label for="avatar-file" class="avatar-text">{{ t("SETTINGS.PROFILE.CHANGE_PHOTO") }}</label>
          </form>
          <div class="text-input-container">
            <SettingsInput
              :inputName="'firstName'"
              :labelText="t('SETTINGS.PROFILE.FIRST_NAME')"
              :value="userInformation.account.firstName"
              :errored="(custInfoValidationErrors.firstName as boolean)"
              @update="updateMemberAccountData($event, 'firstName')"
              :scrollOnFocus="true"
              :maxlength="100"
            />
          </div>
          <div class="text-input-container">
            <SettingsInput
              :inputName="'lastName'"
              :labelText="t('SETTINGS.PROFILE.LAST_NAME')"
              :value="userInformation.account.lastName"
              :errored="(custInfoValidationErrors.lastName as boolean)"
              @update="updateMemberAccountData($event, 'lastName')"
              :scrollOnFocus="true"
              :maxlength="100"
            />
          </div>
          <div class="phone-number">
            <MazPhoneNumberInput
              :name="'phoneNumber'"
              :class="isPhoneNumberErrored ? 'phone-number-error' : ''"
              v-model="userInformation.phoneNumber.value"
              show-code-on-list
              @update="updatePhoneNumber"
              @country-code="updatePhoneNumberCountryCode"
              :success="!isPhoneNumberErrored"
              :no-example="false"
              :default-country-code="defaultCountry"
              :translations="{
                countrySelector: { placeholder: '', error: '', searchPlaceholder: '' },
                phoneInput: { placeholder: '', example: 'eg.' },
              }"
            />
          </div>
          <div class="text-input-container">
            <DropdownFilteredSelector
              :inputName="'gender'"
              :labelText="t('SETTINGS.PROFILE.GENDER')"
              :options="[
                { stringVal: 'Male', mapVal: Gender.MALE },
                { stringVal: 'Female', mapVal: Gender.FEMALE },
                { stringVal: 'Prefer not to say', mapVal: Gender.CRAZY },
              ]"
              :selectedOption="userInformation.account.gender || Gender.CRAZY"
              @changeOption="updateMemberAccountData($event, 'gender')"
              :disabled="false"
              :autofocusInput="false"
              :readonly="true"
              :errored="(custInfoValidationErrors.gender as boolean)"
              :noFilter="true"
              :noClear="true"
            />
          </div>
          <div class="text-input-container">
            <DateInput
              :value="userInformation.birthday"
              :errored="(custInfoValidationErrors.birthday as boolean)"
              @change="(date: string) => updateMemberPrimitiveInfo(date, 'birthday')"
            />
          </div>
          <div class="text-input-container">
            <div class="unitable-container">
              <component
                :is="activeHeightComponent"
                v-bind="activeHeightComponentData.props"
                v-on="activeHeightComponentData.on"
              ></component>
              <DropdownFilteredSelector
                :inputName="'height-unit'"
                :labelText="t('SETTINGS.PROFILE.UNITS')"
                :options="[
                  { stringVal: HeightUnit.CM, mapVal: HeightUnit.CM },
                  { stringVal: HeightUnit.INCHES, mapVal: HeightUnit.INCHES },
                ]"
                :selectedOption="userInformation.heightUnit"
                @changeOption="updateMemberPrimitiveInfo($event, 'heightUnit')"
                :disabled="false"
                :autofocusInput="false"
                :readonly="true"
                :errored="(custInfoValidationErrors.weightUnit as boolean)"
                :noFilter="true"
                :noClear="true"
              />
            </div>
          </div>
          <div class="text-input-container">
            <div class="unitable-container">
              <SettingsInput
                :inputName="'weight'"
                :labelText="t('SETTINGS.PROFILE.WEIGHT')"
                :value="userInformation.weight"
                :errored="(custInfoValidationErrors.weight as boolean)"
                @update="updateMemberPrimitiveInfo($event, 'weight')"
                :scrollOnFocus="true"
                :maxlength="6"
              />
              <DropdownFilteredSelector
                :inputName="'weight-unit'"
                :labelText="t('SETTINGS.PROFILE.UNITS')"
                :options="[
                  { stringVal: WeightUnit.KG, mapVal: WeightUnit.KG },
                  { stringVal: WeightUnit.POUNDS, mapVal: WeightUnit.POUNDS },
                ]"
                :selectedOption="(userInformation.weightUnit as WeightUnit)"
                @changeOption="updateMemberPrimitiveInfo($event, 'weightUnit')"
                :disabled="false"
                :autofocusInput="false"
                :readonly="true"
                :errored="(custInfoValidationErrors.weightUnit as boolean)"
                :noFilter="true"
                :noClear="true"
              />
            </div>
          </div>
          <div class="text-input-container">
            <DropdownFilteredSelector
              :inputName="'contentLanguage'"
              :labelText="t('SETTINGS.PROFILE.CONTENT_LANGUAGE_CHOOSE')"
              :options="[
                { stringVal: LOCALE.EN.toLowerCase(), mapVal: LOCALE.EN },
                { stringVal: LOCALE.DE.toLowerCase(), mapVal: LOCALE.DE },
                { stringVal: LOCALE.RU.toLowerCase(), mapVal: LOCALE.RU },
              ]"
              :selectedOption="userInformation.contentLocale"
              @changeOption="updateMemberPrimitiveInfo($event, 'contentLocale')"
              :disabled="false"
              :autofocusInput="false"
              :readonly="true"
              :errored="(custInfoValidationErrors.contentLocale as boolean)"
              :noFilter="true"
              :noClear="true"
            />
          </div>
        </div>
      </div>
      <div v-if="submitMessage" class="submit-message">
        <p>{{ submitMessage }}</p>
      </div>
      <div class="button-container">
        <div data-action="save" :class="{ button: true, disabled: disabledSubmitButton }" @click="onSubmit">
          <div class="button-text">{{ t("SETTINGS.PROFILE.SAVE") }}</div>
        </div>
      </div>
    </SettingsDetailLayout>
  </div>
</template>

<style scoped lang="pcss">
@import "maz-ui/css/main.css";
@import "@/assets/styles/layout.css";
@import "@/assets/styles/buttons.css";
@import "@/assets/styles/ui.css";
@import "@/assets/styles/settings.css";

@mixin phone-number;
@mixin bioniqButton;

.information-container {
  margin-bottom: 5em;
}
.title {
  font-weight: bold;
  margin-bottom: 1em;
}
.user-information {
  margin-bottom: 2em;
}
.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0 2em 0;
  row-gap: 0.5em;
  & .avatar-img {
    width: 6em;
    height: 6em;
    border-radius: 1em;
    overflow: hidden;
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  & .avatar-text {
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    color: var(--clr-blue-1);
  }
}

.button-container{
  display:flex;
  justify-content: flex-end;
  width: 100%;
  @media (--max-tablet-width) {
    justify-content: center;
  }
}

.text-input-container {
  & .unitable-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & div:first-child {
      flex: 4;
    }
    & div:nth-child(2) {
      flex: 1;
      margin-left: 1.5em;
    }
  }
}

.text-input-container, .phone-number {
  margin: 0 auto 1em auto;
  display:flex;
  align-items: center;
  width: 60%;
  & .text-label{
    width:30%;
  }
  @media (--max-tablet-width) {
    align-items: flex-start;
      flex-direction: column;
      & .text-label{
    width:100%;
  }
  }
}

.text-label{
font-size:1em;
}

.phone-number {
  & .phone-explanation {
    margin-left: 1em;
    color: var(--clr-grey-2);
    font-size: 0.9rem;
    margin-top: 0.5em;
  }
  & .phone-explanation.errored {
    color: var(--clr-red-1);
  }
}

.proceed {
  margin-top: 1.5em;
  & .button {
    width: 100%;
  }
  & .button.disabled {
    cursor: not-allowed;
  }
}

@media (--max-tablet-width) {
  .text-input-container, .phone-number {
    width: 80%;
  }
  & .unitable-container {
    & div:first-child {
      flex: 3;
    }
  }
}

@media (--max-phone-width) {
  .text-input-container, .phone-number {
    width: 100%;
  }
  & .unitable-container {
    & div:first-child {
      flex: 2;
    }
    & div:nth-child(2) {
      margin-left: 1em;
    }
  }
  .avatar-container {
    & .avatar-img {
      width: 5em;
      height: 5em;
      border-radius: 0.8em;
    }
  }
  .information-container {
    margin-bottom: 0;
  }
  .proceed {
    position: fixed;
    z-index: 1;
    width: 90%;
    bottom: 1em;
    left: 5%;
  }
}
</style>
