<script setup lang="ts">
import ButtonMain from "@/components/ui/ButtonMain.vue";
import { useAuthStore } from "@/stores/auth";
import { RouteName } from "@/types/AuthTypes";
import AuthCheckEmail from "@/components/layout/auth/AuthCheckEmail.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const authStore = useAuthStore();

const handleNext = () => {
  authStore.resetData();
  authStore.redirectTo(RouteName.AUTH_EMAIL);
};
</script>

<template>
  <AuthCheckEmail>
    <template v-slot:text>
      <i18n-t class="info-text" keypath="LOGIN.EMAIL_SENT_MSG" tag="div">
        <span>{{ authStore.email || "johndoe@gmail.com" }}</span>
      </i18n-t>
    </template>
    <template v-slot:button>
      <div class="next-button">
        <ButtonMain @click="handleNext">
          <template v-slot:btnText>{{ t("LOGIN.SIGN_IN_AGAIN") }}</template>
        </ButtonMain>
      </div>
    </template>
  </AuthCheckEmail>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/auth.css";

@mixin info-text;
@mixin info-btn;
</style>
