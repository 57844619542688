<script setup lang="ts">
import { ref } from "vue";
import StatusIcon from "@/components/blood-drop/StatusIcon.vue";
import GraphLine from "@/components/blood-drop/GraphLine.vue";
import CheckupModal from "@/components/blood-drop/CheckupModal.vue";
import { useEventListener } from "@/composables/useEventListener";
import { useRouter, useRoute } from "vue-router";
import type { MarkerShort } from "@/stores/checkup-results";
import { useStatusReference } from "@/composables/useStatusReference";
import { RouteQuery } from "@/types/RouteTypes";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const props = defineProps<{
  bloodTestId: number;
  marker: MarkerShort;
}>();

const showModal = ref(false);

const { percentValue, markerReferenceValues, statusType } = useStatusReference(props.marker);

const closeModal = (e?: KeyboardEvent) => {
  if (e && e.key !== "Escape") return;

  showModal.value = false;
  router.push({ query: {} });
};

const openModal = (e: Event) => {
  router.push({ query: { [RouteQuery.ELEMENT_ID]: props.bloodTestId, markerId: props.marker.id } });
  showModal.value = true;
  e.stopPropagation();
};

useEventListener(window, "keydown", closeModal);

// ----- Show Content immediatelly if Route says so -----
const openModalIfRouteContainsCorrectNr = () => {
  if (!Object.keys(route.query).length || !route.query.markerId || !route.query[RouteQuery.ELEMENT_ID]) return;

  if (
    parseInt(route.query.markerId as string) === props.marker.id &&
    parseInt(route.query[RouteQuery.ELEMENT_ID] as string) === props.bloodTestId
  )
    showModal.value = true;
};
openModalIfRouteContainsCorrectNr();
// ----- Show Content immediatelly if Route says so -----
</script>

<template>
  <div class="checkup-card-container" @click="openModal">
    <template v-if="!!statusType">
      <div class="top">
        <div class="marker-type">{{ props.marker.bloodMarker.name }}</div>
        <div class="status-cont">
          <StatusIcon :status="statusType" />
        </div>
      </div>
      <div class="reference-text">
        <div class="text">Reference value of the laboratory</div>
        <div class="values">{{ markerReferenceValues }}</div>
      </div>
      <div class="graph-container">
        <div class="value">{{ `${props.marker.value} ${props.marker.unit}` }}</div>
        <GraphLine :valuePercent="percentValue" />
        <div class="row low-high">
          <div>{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.LOW") }}</div>
          <div>{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.HIGH") }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="top">
        <div class="marker-type">{{ props.marker.bloodMarker.name }}</div>
      </div>
      <div class="marker-missing-info">
        <div class="question-icon">
          <img src="@/assets/icons/question-mark.webp" alt="question" />
        </div>
        <div class="text">{{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.STATUS.UNKNOWN") }}</div>
      </div>
    </template>
  </div>

  <CheckupModal
    v-if="showModal"
    @clickOutside="closeModal"
    :markerId="props.marker.id"
    :bloodTestId="props.bloodTestId"
  />
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/blood-drop.css";

.checkup-card-container {
  box-sizing: border-box;
  background-color: var(--clr-grey-bg-main);
  border-radius: 1em;
  padding: 1.5em;
  width: calc(25% - var(--cards-gap));
  min-height: var(--cards-height);
  cursor: pointer;
  margin-bottom: 2em;
  border: 1px solid var(--clr-grey-3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@supports (row-gap: 1em) {
  .checkup-card-container {
    margin-bottom: 0;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top .status-cont {
  height: 1.8em;
}
.top .marker-type {
  font-size: 1.2em;
  font-weight: bold;
}
.reference-text {
  margin-top: 1em;

  color: var(--clr-blue-1);
  font-size: 0.9em;
}
.graph-container {
  margin-top: 1em;
}
.graph-container .value {
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 1em;
}
.graph-container .low-high {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

@mixin marker-missing-info;

@media (--max-laptop-width) {
  .checkup-card-container {
    width: calc(33.33% - var(--cards-gap));
  }
}

@media (--max-tablet-width) {
  .checkup-card-container {
    width: calc(50% - var(--cards-gap) / 2);
  }
}

@media (--max-phone-width) {
  .checkup-card-container {
    width: 100%;
  }
}
</style>
