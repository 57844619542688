<script setup lang="ts">
import SettingsLayout from "@/components/layout/settings/SettingsLayout.vue";
import { isLimitedAppFunctionality } from "@/util/functions";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <SettingsLayout v-if="!isLimitedAppFunctionality()">
    <RouterView />
  </SettingsLayout>
  <div v-else class="limited-functionality-bg">
    <div class="limited-text">{{ t("DEV.IN_PROGRESS") }}</div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";

@mixin limited-func-bg;
</style>
