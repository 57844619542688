<script setup lang="ts">
import AuthError from "@/components/layout/auth/AuthError.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <AuthError>
    <span>{{ t("LOGIN.ERROR.MAGIC") }}</span>
  </AuthError>
</template>

<style scoped>
@import "@/assets/base.css";

.info-text span {
  color: var(--clr-red-3);
}
</style>
