import { QuestionnairesKey, useQuestionnairesStore, questMapper } from "@/stores/questionnaires";
import { preserveRouteQueries } from "@/router/guards/guards";
import { RouteName } from "@/types/AuthTypes";
import type { RouteLocationRaw } from "vue-router";
import router from "@/router/index";
import { useMemberStore } from "@/stores/member";
import { isLimitedAppFunctionality } from "@/util/functions";

export const fetchQuestionnaires = async () => {
  const questionnairesStore = useQuestionnairesStore();
  if (questionnairesStore.questionnaires) return true;

  const [err, _res] = await questionnairesStore.fetchQuestionnaireByKey(Object.values(QuestionnairesKey));
  if (err) return false;
  return true;
};

export const fetchQuestionnaireAnswers = async (key: QuestionnairesKey) => {
  const questionnairesStore = useQuestionnairesStore();
  if (questionnairesStore.answers[key]) return true;

  const [err, _res]: any = await questionnairesStore.getQuestionnaireAnswers(key);
  if (err) return false;
  return true;
};

export const setActiveQuestionnaire = (key: QuestionnairesKey) => {
  const questionnairesStore = useQuestionnairesStore();
  questionnairesStore.activeQuestionnaireKey = key;

  return true;
};

export const changeParamTo = (to: any, nr: number) => {
  if (nr === parseInt(to.params.nr)) return true;
  return { name: to.name, params: { nr } };
};

const updateFirstMandatoryQuestionnaireWithProfileQuestions = () => {
  const memberStore = useMemberStore();
  if (memberStore.isMandatoryProfileInfoCompleted) return;

  const questStore = useQuestionnairesStore();
  questStore.pushMandatoryProfileInfoQuestionsToActiveQuestionnaire();
};

export const questionnairesMandatoryGuard = async (to: any, from: any) => {
  if (isLimitedAppFunctionality()) return true;

  const questStore = useQuestionnairesStore();

  const memberStore = useMemberStore();
  if (!memberStore.profile) return { name: RouteName.AUTH_EMAIL };

  if (!memberStore.mandatoryUncompletedQuestionnaires.length && memberStore.isMandatoryProfileInfoCompleted)
    return true;

  // * This means user is currently completing his questionnaire and he did not finish it
  if (from.matched.some((match: any) => match.name === RouteName.QUESTIONNAIRE_MAIN)) return false;

  for (const key of Object.keys(questMapper)) {
    if (memberStore.mandatoryUncompletedQuestionnaires.includes(key as QuestionnairesKey)) {
      questStore.activeQuestionnaireKey = key as QuestionnairesKey;
      const returnTo = dynamicQuestionnaireRoute(key as QuestionnairesKey);
      const nextRoute = preserveRouteQueries(returnTo, to.query);
      return nextRoute;
    }
  }
  console.info("ONLY MANDATORY PROFILE INFO IS NOT COMPLETED");

  return true;
};

export const dynamicQuestionnaireRoute = (key: QuestionnairesKey): RouteLocationRaw => {
  const routeName: RouteName = questMapper[key]!.name;
  const routeExists = router.hasRoute(routeName);

  if (!routeExists) {
    router.addRoute(RouteName.QUESTIONNAIRE_MAIN, {
      path: `${routeName}/:nr`,
      component: () => import("@/views/questionnaire/QuestionnaireActive.vue"),
      name: routeName,
      props: (route: any) => ({ nr: parseInt(route.params.nr as string) }),
      beforeEnter: [
        () => fetchQuestionnaireAnswers(key),
        (to: any) => changeParamTo(to, 1),
        () => setActiveQuestionnaire(key),
        () => updateFirstMandatoryQuestionnaireWithProfileQuestions(),
      ],
    });
  }

  return questMapper[key]!;
};
