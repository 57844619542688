<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import ToggleableListItemMain from "@/components/ui/ToggleableListItemMain.vue";
import CheckupCard from "@/components/blood-drop/CheckupCard.vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCheckupResultsStore, type BloodTests, type BloodTestResults } from "@/stores/checkup-results";
import CustomSuspense from "@/components/helpers/CustomSuspense.vue";
import errorImg from "@/assets/icons/error-1.webp";
import { useToggleableListItemMain } from "@/composables/ui/useToggleableListItemMain";
import { createdAtToDateString } from "@/util/dates";
import AnalyticsActions from "@/components/blood-drop/AnalyticsActions.vue";
import { isLimitedAppFunctionality } from "@/util/functions";

const { t } = useI18n();
const checkupStore = useCheckupResultsStore();
const { bloodTests, bloodTestResults } = storeToRefs(checkupStore);
const { listItemLayoutToggled, listItemsHandler: bloodTestsHandler } = useToggleableListItemMain<
  BloodTests,
  BloodTestResults
>(bloodTests, bloodTestResults);
// ----- Each Result -----

const errorFetchBloodTest = { text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.ERROR_API_TEST"), icon: errorImg };

const bloodTestTitle = (index: number): string => {
  return `${t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.CHECKUP", 1)} Nº${index + 1}`;
};

// ----- Each Result -----

// ----- All Results Handlers -----
const makeBloodTestsApiCall = ref(false);

const errorFetchBloodTests = { text: t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.ERROR_API_TESTS"), icon: errorImg };

const bloodTestsEmpty = computed<boolean>(() => bloodTests.value.totalCount === null);

const checkIfBloodTestsAreLoaded = () => {
  makeBloodTestsApiCall.value = bloodTestsEmpty.value ? true : false;
};

watchEffect(() => checkIfBloodTestsAreLoaded());
// ----- All Results Handlers -----
</script>

<template>
  <div v-if="!isLimitedAppFunctionality()" class="analytics-container">
    <div class="checkup-results-container">
      <CustomSuspense
        :apiCall="() => checkupStore.fetchBloodTests()"
        :errorObj="errorFetchBloodTests"
        :shouldMakeApiCall="makeBloodTestsApiCall"
        :shouldShowContent="!bloodTestsEmpty"
      >
        <template v-slot:content>
          <div v-if="!bloodTests.totalCount" class="no-results-container">
            {{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.EMPTY") }}
          </div>
          <ToggleableListItemMain
            v-for="(bloodTest, index) in bloodTests.results"
            :key="bloodTest.id"
            :elementId="bloodTest.id"
            @layoutToggled="listItemLayoutToggled"
          >
            <template v-slot:title>{{ bloodTestTitle(index) }}</template>
            <template v-slot:date>{{ createdAtToDateString(bloodTest.createdAt) }}</template>
            <template v-slot:main-content>
              <CustomSuspense
                :apiCall="() => checkupStore.fetchBloodTest(bloodTest.id)"
                :errorObj="errorFetchBloodTest"
                :shouldMakeApiCall="bloodTestsHandler[bloodTest.id].shouldMakeApiCall"
                :shouldShowContent="!!bloodTestResults[bloodTest.id]"
              >
                <template v-slot:content>
                  <div v-if="!bloodTestResults[bloodTest.id].bloodMarkerResults.length" class="no-result-container">
                    {{ t("SECTION.BLOOD_DROP.SECTIONS.CHECKUPS.MARKER.EMPTY") }}
                  </div>
                  <CheckupCard
                    v-for="marker in bloodTestResults[bloodTest.id].bloodMarkerResults"
                    :key="marker.id"
                    :marker="marker"
                    :bloodTestId="bloodTestResults[bloodTest.id].id"
                  />
                </template>
              </CustomSuspense>
            </template>
          </ToggleableListItemMain>
        </template>
      </CustomSuspense>
    </div>
    <AnalyticsActions />
  </div>

  <div v-else class="limited-functionality-bg">
    <div class="limited-text">{{ t("DEV.IN_PROGRESS") }}</div>
    <AnalyticsActions />
  </div>
</template>

<style scoped>
@import "@/assets/styles/blood-drop.css";
@import "@/assets/styles/layout.css";

@mixin limited-func-bg;

.limited-functionality-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  & .limited-text {
    font-family: "Montserrat";
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--clr-blue-bioniq);
  }
}
.analytics-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.checkup-results-container {
  position: relative;
  min-height: 20rem;
}
.no-results-container {
  text-align: center;
  font-size: 1.5em;
}
.no-result-container {
  width: 100%;
  min-height: var(--cards-height);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}
.checkup-results-container :deep(.error-container) {
  min-height: var(--cards-height);
  width: 70%;
  margin: 0 auto;
}

.checkup-results-container :deep(.content .custom-suspense-container) {
  min-height: var(--cards-height);
  width: 100%;
}
</style>
